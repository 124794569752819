$btn-focus-box-shadow: none !important;
$theme-colors: (
  'primary': #3fada8,
  'success': #053225,
);
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');
@import '~bootstrap/scss/bootstrap';

* {
  transition-duration: 300ms;
}

// .form-control {
//   box-shadow: none !important;
// }

.pointer {
  cursor: pointer;
}

html {
  font-family: 'Open Sans', sans-serif;
}

body {
  font-family: 'Open Sans', sans-serif;
  color: #090c08;
  overflow-x: hidden;
}

.main-heading {
  position: relative;
  overflow: hidden;
}

.main-heading:before {
  content: '';
  display: inline-block;
  height: 0.5em;
  vertical-align: bottom;
  width: 1%;
  margin-right: 10px;
  border-top: 1px solid #3fada8;
}

.main-heading:after {
  content: '';
  display: inline-block;
  height: 0.5em;
  vertical-align: bottom;
  width: 100%;
  margin-right: -100%;
  margin-left: 10px;
  border-top: 1px solid #3fada8;
}

.badge-main {
  background-color: #57a39f;
  color: #fbfbff;
}

.badge-main:hover {
  background-color: #3fada8;
}

.card:hover > .card {
  opacity: 0.5;
}

button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}

button.learn-more {
  width: 100%;
  height: auto;
}

button.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #3fada8;
  border-radius: 1.625rem;
}

button.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}

button.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

button.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: '';
  top: -0.29rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
}

button.learn-more .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: #3fada8;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
}

button:hover .circle {
  width: 100%;
}

button:hover .circle .icon.arrow {
  background: #fff;
  transform: translate(1rem, 0);
}

button:hover .button-text {
  color: #fff;
}

.form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 10px;
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 0.4em;
  background-color: white;
  border-radius: 2px;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.form:hover {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

#heading {
  text-align: center;
  margin-top: 1em;
  color: #3fada8;
  font-size: 2em;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.field {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 0.5em;
  border-radius: 10px;
  padding: 0.6em;
  border: none;
  outline: none;
  // color: white;
  // background-color: #3fada8;
  -webkit-box-shadow: inset 0px 0px 0px 1px rgb(5, 5, 5);
  box-shadow: inset 0.5px 0.5px 1px 2px rgb(1, 1, 1);
  // box-shadow: #000;
}

.input-icon {
  height: 1.3em;
  width: 1.3em;
  fill: black;
}

.input-field {
  background: none;
  border: none;
  outline: none;
  width: 100%;
  color: black;
}

.form .btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: 2.5em;
}

.button1 {
  padding: 0.5em;
  padding-left: 1.1em;
  padding-right: 1.1em;
  border-radius: 5px;
  margin-right: 0.5em;
  border: none;
  outline: none;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  color: #252525;
  background-color: white;
  border-color: #3fada8;
  border: 2px solid #3fada8;
}

.button1:hover {
  background-color: #3fada8;
  color: white;
}

.button2 {
  padding: 0.5em;
  padding-left: 2.3em;
  padding-right: 2.3em;
  border-radius: 5px;
  border: none;
  outline: none;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  color: #252525;
  background-color: white;
}

.button2:hover {
  background-color: black;
  color: white;
}

.button3 {
  margin-bottom: 3em;
  padding: 0.5em;
  border-radius: 5px;
  border: none;
  outline: none;
  -webkit-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  color: #252525;
  background-color: white;
}

.button3:hover {
  background-color: red;
  color: white;
}

.modal-body {
  background-color: #3fada8;
}

.modal-content {
  width: 100% !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  // color: white;
  opacity: 0.7; /* Firefox */
}

// Card
.card {
  position: relative;
  height: 254px;
  // background: #f00;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5%;
  width: 28%;
  margin-top: 2%;
}

.card b {
  position: absolute;
  inset: 6px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
}

.card img {
  position: absolute;
  z-index: 3;
  scale: 0.8;
  opacity: 1;
  transition: 0.5s;
}

.card:hover img {
  scale: 0.5;
  opacity: 0.1;
  transform: translateY(-70px);
}

.card .content {
  position: absolute;
  z-index: 3;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: scale(0);
  transition: 0.5s;
}

.card:hover .content {
  transform: scale(0.85);
  bottom: 20%;
}

.content .title {
  position: relative;
  color: #000;
  font-weight: 500;
  line-height: 1em;
  font-size: 1em;
  letter-spacing: 0.1em;
  // text-transform: uppercase;
  text-align: center;
  font-family: 'Georgia';
  margin-bottom: 5%;
}

.content .title span {
  font-weight: 300;
  font-size: 0.7em;
}

.dev-name {
  margin-bottom: 45%;
  z-index: 0;
  font-family: 'Times New Roman', Times, serif;
}

.dev-img {
  width: 50%;
  margin-top: 10%;
  object-fit: cover;
  border-radius: 100%;
  z-index: 0;
}

.button_wrap {
  margin-top: 3%;
  text-align: center;
}
.button_wrap a {
  width: 55px;
  height: 25px;
  border-radius: 10%;
  overflow: hidden;
  // background: #e8eceb;
  display: inline-table;
  color: #3fada8;
  font-size: large;
}

.button_wrap a span {
  display: table-cell;
  vertical-align: middle;
}

.button_wrap a:hover {
  color: #3fadd9;
}

.toggle-text {
  z-index: 10;
}

.toggle-text:hover {
  cursor: pointer;
  text-decoration: underline;
}
